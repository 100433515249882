import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex, Box} from 'rebass';
import Fade from 'react-reveal/Fade';
import { cleanProjectData } from '../utils';
import Banner from '../components/Banner';
import ButtonNav from '../components/ButtonNav';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Video from '../video/sydneytrains.mp4';

const Transport = () => { 
  const projectData = useStaticQuery(graphql`
    query  {
     allContentfulProject(filter: {projectUrl: {eq: "transport"}}) {
      ...projectDataFragment
     }
   }`)
   const data = cleanProjectData(projectData);
   const {
      bannerImage,
      challengeContent,
      methodsContent,
      name,
      deliverablesContent,
      outcomesContent,
   } = data;

  return (
    <Layout>
      <Banner variant src={bannerImage} title={name} />
      <Section.Container id="about">
        <Fade>
          <Section.HeaderLeft name="THE CHALLENGE" />
          <Flex mb={4} justifyContent="center" flexWrap='wrap'>
            <Box width={[1, 1/2]} px={4} pb={2} mb={4}>
              {challengeContent[2]}
            </Box>
            <Box width={[1, 1/2]} px={4} pb={2} mb={4}>
              {challengeContent[0]}
              {challengeContent[1]}
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="METHODS" />
          <Flex justifyContent="center" flexWrap='wrap'>
            <Box width={1} px={4} mb={[null,4]}>
              {methodsContent[0]}
              {methodsContent[1]}
              {methodsContent[2]}
              {methodsContent[3]}
            </Box>
          </Flex>
        </Fade>
        <Fade>
          <Flex justifyContent="center" flexWrap='wrap'>
            <Box width={[1, 1/2]} px={4} mb={[null,4]}>
              {methodsContent[4]}
              {methodsContent[5]}
            </Box>
            <Box width={[1, 1/2]} px={4} mb={4}>
              {methodsContent[6]}
              {methodsContent[7]}
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="DELIVERABLES" />
          <Flex justifyContent="center" flexWrap='wrap' mb={5}>
            <Box width={[1,1/2]} px={[4]} pb={2} mb={4}>
              {deliverablesContent[0]}
            </Box>
            <Box width={[1,1/2]} px={[4]} pb={2} mb={4}>
              {deliverablesContent[1]}
            </Box>
          </Flex>
        </Fade>
        <Fade>
          <Section.HeaderLeftSmall name="EDIT TRAIN ARRIVAL FLOW" />
          <Flex justifyContent="center" flexWrap='wrap' mb={5}>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[2]}
            </Box>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[3]}
            </Box>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[4]}
            </Box>
            <Box width={[1,1/2,1/4]} px={4} pb={2} mb={4}>
              {deliverablesContent[5]}
            </Box>
          </Flex>
        </Fade>
              
        <Fade>
          <Section.HeaderLeftSmall name="DEMO" />

          <Flex justifyContent="center" flexWrap='wrap' mb={5}> 
            <Box width={[1, 2/3]} px={4} pb={2} mb={4}>
              {deliverablesContent[6]}
              {deliverablesContent[7]}
            </Box>
            <Box width={[1, 1/3]} px={[4]} pb={2} mb={4}>
              <video style={{width:'100%'}} autoPlay loop muted>
                <source src={Video} type='video/mp4' />
              </video>
            </Box>
            
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="OUTCOMES" />
          <Flex justifyContent="center" flexWrap='wrap'>
            <Box width={[1, 1/2]} px={4} pb={2} mb={4}>
              {outcomesContent[0]}
            </Box>
            <Box width={[1, 1/2]} px={4} pb={2} mb={4}>
              {outcomesContent[1]}           
            </Box>
            <ButtonNav 
              link="/education"
              text="Next Project"
            /> 
          </Flex>
        </Fade>
      </Section.Container>
      
    </Layout>
    )
  }

export default Transport;

